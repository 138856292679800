import React, { FC, Component, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import {
  removeCompany,
  setCompany,
} from "../core/feature/company/companySlice";
import * as API from "../core/service/api_invoice.js";
import down from "../assets/images/icon_invoicelink/down.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { setLoading } from "../core/feature/config/configSlice";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { formatCurrency, status } from "../config/global";
import InputComponent from "../components/InputComponent";
import Invoice from "./Invoice";
import InvoiceMaster from "./invoiceMaster";
import NotFoundCO from "../components/NotFound";

const Index = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const company: any = useAppSelector((state) => state.company.company);
  const [invoice, setInvoice] = useState(true);
  const [invoiceMaster, setInvoiceMaster] = useState(true);
  const [NotFound, setNotfound] = useState(true);

  const getInvoiceLink = async () => {
    dispatch(setLoading(true));

    await API.getInvoice(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        if (res.data.data?.master == null || undefined || "")
          return setInvoice(false);
        if (res.data.data?.master !== null || undefined || "")
          return setInvoiceMaster(false);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
        if (err.response.status === 404) {
          setNotfound(false);
          setInvoice(true);
          setInvoiceMaster(true);
        }
      });
  };

  const title = "DIGITAL INVOICE";
  document.title = title;

  useEffect(() => {
    getInvoiceLink();
  }, []);

  return (
    <div>
      <Invoice show={invoice} />
      <InvoiceMaster show={invoiceMaster} />
      <NotFoundCO show={NotFound} />
    </div>
  );
};

export default Index;
