import React, { Component, useEffect, useState } from "react";
import {} from "../core/feature/store";
import {} from "../core/feature/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const NotFoundData = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="h-[320px] flex items-center justify-center">
        <div className="text-sm md:text-lg text-slate-500 italic">data tidak ditemukan</div>
      </div>
    </>
  );
};

export default NotFoundData;