import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import {
  removeCompany,
  setCompany,
} from "../core/feature/company/companySlice";
import * as API from "../core/service/api_invoice.js";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { setLoading } from "../core/feature/config/configSlice";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency, status, formatDate } from "../config/global";
import InputComponent from "../components/InputComponent";
import NotFoundData from "../components/NotFoundData";
import LoadMoreButton from "../components/loadMoreButton";
import { ToastContainer, toast } from "react-toastify";
import { ValidateError } from "../core/service/interceptor_error_message";
import Tooltip from "../assets/images/icon_invoicelink/comingsoon.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import TooltipModal from "../components/tooltipModal";

interface InvoiceMasterProps {
  show: any;
}
const InvoiceMaster: FC<InvoiceMasterProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [price, setPrice] = useState("");
  const [id, setId] = useState("");
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [show, setShow] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [allowedPartial, setAllowedPartial] = useState(false);
  const [tab, setTab] = useState<any>([]);
  const [invoices, setInvoices] = useState([]);
  const [tooltip, setTooltip] = useState(false);
  const [userType, setUserType] = useState("");
  const [showAutoPlay, setShowAutoPlay] = useState(false);

  const getInvoiceLink = async () => {
    dispatch(setLoading(true));
    await API.getInvoice(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setCompany(res.data.data));
        setData(res.data.data);
        setInvoices(res.data.data.invoices);
        setCompanyLogo(res.data.data.companyLogo);
        setTotalElements(res.data.data.total);
        setUserType(res.data.data.invoices[0]?.userType?.toLowerCase());
        setShowAutoPlay(res.data.data.invoices[0]?.config?.isAutoPayment);
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const paymentInvoiceLink = async (event: any) => {
    event.preventDefault();
    dispatch(setLoading(true));
    let query = {
      amount: price,
    };
    await API.invoicePayment(id, query, data.createdBy, userType)
      .then((res) => {
        dispatch(setLoading(false));
        window.location.href = `https://${res.data.data.checkoutLink}`;
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast.error(ValidateError(err.response.data));
      });
  };

  const checked = (el: any) => {
    if (el == id) return true;
  };

  const showHide = (index: any) => {
    if (show !== index) return setShow(index);
    if (show == index) return setShow("");
  };
  const statusFunction = () => {
    const filter1 = ["UNPAID", "PARTIAL", "PAID"];
    const filter2 = ["UNPAID", "PARTIAL"];
    const filter3 = ["PAID"];

    switch (statusFilter) {
      case "ALL":
        return setTab(filter1);
      case "UNPAID":
        return setTab(filter2);
      case "PAID":
        return setTab(filter3);
      default:
        break;
    }
  };

  useEffect(() => {
    getInvoiceLink();
    statusFunction();
  }, [finalPrice, statusFilter]);

  return (
    <div className="bg-slate-100" hidden={props.show}>
      <Navbar />
      <div className="h-full min-h-screen font-poppins md:text-base bg-slate-100 text-sm">
        <div className="hidden md:block pt-20" />
        <div className="md:w-[40%] w-full mx-auto bg-white py-4">
          <div className="md:w-[65%] w-[90%] mx-auto space-y-20">
            <div className="w-full rounded-xl border border-slate-200">
              <div className="relative bg-[#26A69A] bg-opacity-25  rounded-t-lg p-2 text-center  font-semibold text-lg">
                <span className="text-[#26A69A]">{data.master?.title}</span>
              </div>
              <div className="p-4 space-y-2 mx-auto text-[#9A9999] md:text-sm text-xs">
                <div
                  className={`${
                    showAutoPlay ? "block d-flex justify-end" : "hidden"
                  }`}
                >
                  <img
                    src={Tooltip}
                    alt=""
                    onClick={() => {
                      setTooltip(true);
                      setId("");
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <div>
                  Untuk,{" "}
                  <span className="font-semibold text-[#252525]">
                    {data.invoiceReceiverName}
                  </span>
                </div>
                <div className="text-justify">{data?.master?.description}</div>

                {data?.master?.item_details?.map((el: any, index: any) => {
                  return (
                    <div className="mt-3 mx-auto w-[90%]" key={index}>
                      <div className="grid grid-cols-3 gap-4">
                        <span className="col-span-2 justify-start overflow-x-auto font-medium">
                          {el.name}
                        </span>
                        <div
                          className="flex justify-end cursor-pointer"
                          onClick={() => showHide(index)}
                        >
                          <span className="mr-1" hidden={show === index}>
                            {formatCurrency(el.total)}
                          </span>
                          <FontAwesomeIcon
                            className="mt-[0.2rem]"
                            icon={show === index ? faChevronUp : faChevronDown}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <div
                        className={
                          show === index ? "block text-[#728F9E]" : "hidden"
                        }
                      >
                        <div className="grid grid-cols-2">
                          <div className="flex justify-start ml-2">Harga</div>
                          <div className="flex justify-end">
                            {formatCurrency(el.amount)}
                          </div>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="flex justify-start ml-2">Diskon</div>
                          <div className="flex justify-end">
                            {formatCurrency(el.discount)}
                          </div>
                        </div>
                        <div className="grid grid-cols-2">
                          <div className="flex justify-start ml-2">Total</div>
                          <div className="flex justify-end">
                            {formatCurrency(el.total)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="flex justify-center mt-4 w-[90%] mx-auto md:text-base text-sm">
                  <div className="grid grid-cols-2 w-full">
                    <div className="flex justify-start text-[#26A69A] font-semibold">
                      Total
                    </div>
                    <div className="flex justify-end font-semibold text-[#26A69A]">
                      {formatCurrency(data.master?.total)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 md:w-[40%] w-[90%] mx-auto mt-10 bg-[#26A69A] rounded-[10px] p-1 md:text-sm text-xs font-medium">
          <button
            className={`${
              statusFilter == "ALL"
                ? "text-[#26A69A] py-2 bg-white w-[100%] rounded-lg"
                : "block text-white"
            } text-center hover:text-[#26A69A] py-2`}
            onClick={() => {
              setStatusFilter("ALL");
              setId("");
              setPage(1);
            }}
          >
            Semua
          </button>
          <button
            className={`${
              statusFilter == "UNPAID"
                ? "text-[#26A69A] py-2 bg-white w-[100%] rounded-lg"
                : "block text-white"
            } text-center hover:text-[#26A69A] py-2`}
            onClick={() => {
              setStatusFilter("UNPAID");
              setId("");
              setPage(1);
            }}
          >
            Belum Dibayar
          </button>
          <button
            className={`${
              statusFilter == "PAID"
                ? "text-[#26A69A] py-2 bg-white w-[100%] rounded-lg"
                : "block text-white"
            } text-center hover:text-[#26A69A] py-2`}
            onClick={() => {
              setStatusFilter("PAID");
              setId("");
              setPage(1);
            }}
          >
            Lunas
          </button>
        </div>

        {data.invoices?.filter((el: any) => tab.includes(el.paymentStatus))
          .length > 0 ? (
          <div className="md:w-[40%] w-full mx-auto pt-10">
            {invoices
              ?.filter((el: any) => tab.includes(el.paymentStatus))
              .map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row md:w-full w-[90%] mx-auto bg-white p-3 rounded-lg border border-slate-400 mb-2 relative md:text-sm text-xs"
                  >
                    <div className="basis-[90%] text-left">
                      <img
                        src={status(item.paymentStatus) as any}
                        className={`${
                          item.paymentStatus == "UNPAID" ? "md:w-32" : "md:w-20"
                        } w-26`}
                      />

                      <div className="text-slate-400">{item._id}</div>
                      <div className="flex space-x-4 mb-1">
                        <div className="text-black text-base md:text-lg">
                          {formatCurrency(item.amount)}
                        </div>
                        <div
                          className="flex items-end"
                          hidden={item.paymentStatus !== "PARTIAL"}
                        >
                          Sisa Bayar : &nbsp;{" "}
                          <span className="text-[#FF9723]">
                            {formatCurrency(item.final)}
                          </span>
                        </div>
                      </div>
                      <div className="text-slate-400">
                        Tanggal invoice :{" "}
                        <span className="font-semibold">
                          {formatDate(item.invoiceDate)}
                        </span>
                      </div>
                      <div className="text-slate-400">
                        Tanggal Jatuh Tempo :{" "}
                        <span className="font-semibold">
                          {formatDate(item.expiredDate)}
                        </span>
                      </div>
                    </div>
                    <div className="basis-[10%] radio-modal flex justify-end items-center">
                      <input
                        hidden={item.paymentStatus == "PAID"}
                        type="radio"
                        checked={checked(item._id)}
                        onClick={() => {
                          setFinalPrice(item.final);
                          setAllowedPartial(item.config.allowPartialPayment);
                        }}
                        className=""
                        value={item._id}
                        onChange={(event) => {
                          setId(event.target.value.trim());
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <NotFoundData />
        )}
        <TooltipModal show={tooltip} setShow={setTooltip} />
        <div hidden={!id} className="md:w-[40%] w-[90%] mx-auto">
          <InputComponent
            value={price}
            setValue={setPrice}
            submit={paymentInvoiceLink}
            final={finalPrice}
            allowed={allowedPartial}
          />
        </div>
      </div>
      <Footer companyLogo={companyLogo} />
    </div>
  );
};

export default InvoiceMaster;
