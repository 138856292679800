import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import {
  removeCompany,
  setCompany,
} from "../core/feature/company/companySlice";
import * as API from "../core/service/api_invoice.js";
import down from "../assets/images/icon_invoicelink/down.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { setLoading } from "../core/feature/config/configSlice";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency, status, formatDate } from "../config/global";
import InputComponent from "../components/InputComponent";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { ValidateError } from "../core/service/interceptor_error_message";
import Tooltip from "../assets/images/icon_invoicelink/comingsoon.svg";
import TooltipModal from "../components/tooltipModal";
import { log } from "util";
interface InvoiceProps {
  show: any;
}
const Invoice: FC<InvoiceProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const company: any = useAppSelector((state) => state.company.company);
  const [data, setData] = useState<any>([]);
  const [price, setPrice] = useState("");
  const [show, setShow] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [allowedPartial, setAllowedPartial] = useState(false);
  const [id, setId] = useState("");
  const [tooltip, setTooltip] = useState(false);
  const [userType, setUserType] = useState("");
  const [showAutoPlay, setShowAutoPlay] = useState(false);

  const getInvoiceLink = async () => {
    dispatch(setLoading(true));
    await API.getInvoice(params.id)
      .then((res) => {
        dispatch(setLoading(false));
        setData(res.data.data);
        dispatch(setCompany(res.data.data));
        setCompanyLogo(res.data.data.companyLogo);
        let data = res.data.data?.invoices?.map((data: any) => {
          return data;
        });
        let dataSpread: any = [...data];
        setFinalPrice(dataSpread[0]?.final);
        setAllowedPartial(dataSpread[0]?.config.allowPartialPayment);
        setId(dataSpread[0]?._id);
        setUserType(dataSpread[0]?.userType.toLowerCase());
        setShowAutoPlay(res.data.data.invoices[0]?.config.isAutoPayment);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast.error(ValidateError(err.response.data));
      });
  };

  const paymentInvoiceLink = async (event: any) => {
    event.preventDefault();
    dispatch(setLoading(true));
    let query = {
      amount: price,
    };
    await API.invoicePayment(id, query, data.createdBy, userType)
      .then((res) => {
        dispatch(setLoading(false));
        window.location.href = `https://${res.data.data.checkoutLink}`;
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (
          err.response.data.detail.type == "AMOUNT_MUST_BE_BIGGER_THAN_ZERO"
        ) {
          toast.error("Nominal Harus Lebih Dari 0");
        }
      });
  };

  const FinalPrice = () => {
    let data = company?.invoices?.map((data: any) => {
      return data.final;
    });
    let dataSpread: any = [...data];
    setFinalPrice(dataSpread[0]);
  };

  const showHide = (index: any) => {
    if (show !== index) return setShow(index);
    if (show == index) return setShow("");
  };

  const showInput = (status: any) => {
    if (tooltip) {
      return true;
    } else {
      if (status == "PAID") return true;
      if (status !== "PAID") return false;
    }
  };

  useEffect(() => {
    getInvoiceLink();
  }, [finalPrice]);

  return (
    <div className="bg-slate-100" hidden={props.show}>
      <div className="h-full min-h-screen font-poppins md:text-base text-sm">
        <Navbar />
        <div className="hidden md:block pt-20" />
        <div className="md:w-[40%] w-full mx-auto ">
          {data.invoices?.map((item: any, index: any) => {
            return (
              <div key={index}>
                <div className="mx-auto space-y-20 bg-white py-4 md:text-sm text-xs">
                  <div hidden>{showInput(item.paymentStatus)}</div>
                  <div className="md:w-[65%] w-[90%] rounded-xl border border-slate-200 mx-auto">
                    <div className="bg-[#26A69A] bg-opacity-25 rounded-t-lg p-2 text-center text-white font-semibold text-lg">
                      <span className="text-[#26A69A]">{item?.title}</span>
                    </div>
                    <div className="p-4 space-y-2 mx-auto text-[#9A9999]">
                      <div
                        className={`${
                          showAutoPlay ? "block d-flex justify-end" : "hidden"
                        }`}
                      >
                        <img
                          src={Tooltip}
                          alt=""
                          onClick={() => {
                            setTooltip(true);
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                      <div>
                        Untuk,{" "}
                        <span className="font-semibold text-[#252525]">
                          {data.invoiceReceiverName}
                        </span>
                      </div>
                      <div className="text-justify">{item.description}</div>

                      {item?.item_details?.map((el: any, index: any) => {
                        return (
                          <div className="mx-auto mt-3 w-[90%]" key={index}>
                            <div className="grid grid-cols-3 gap-4">
                              <div className="col-span-2 justify-start overflow-x-auto font-medium">
                                {el.name}
                              </div>
                              <div
                                className="flex justify-end cursor-pointer"
                                onClick={() => showHide(index)}
                              >
                                <span className="mr-1" hidden={show === index}>
                                  {formatCurrency(el.total)}
                                </span>
                                <FontAwesomeIcon
                                  className="mt-[0.2rem]"
                                  icon={
                                    show === index ? faChevronUp : faChevronDown
                                  }
                                ></FontAwesomeIcon>
                              </div>
                            </div>
                            <div
                              className={
                                show === index
                                  ? "block text-[#728F9E]"
                                  : "hidden"
                              }
                            >
                              <div className="grid grid-cols-2">
                                <div className="flex justify-start ml-2">
                                  Harga
                                </div>
                                <div className="flex justify-end">
                                  {formatCurrency(el.amount)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="flex justify-start ml-2">
                                  Diskon
                                </div>
                                <div className="flex justify-end">
                                  {formatCurrency(el.discount)}
                                </div>
                              </div>
                              <div className="grid grid-cols-2">
                                <div className="flex justify-start ml-2">
                                  Total
                                </div>
                                <div className="flex justify-end">
                                  {formatCurrency(el.total)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex justify-center mt-4 w-[90%] mx-auto md:text-base text-sm">
                        <div className="grid grid-cols-2 w-full">
                          <div className="flex justify-start text-[#26A69A] font-semibold">
                            Total
                          </div>
                          <div className="flex justify-end font-semibold  text-[#26A69A] ">
                            {formatCurrency(item.amount)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-[65%] w-[90%] mx-auto md:text-sm text-xs">
                    <div className="text-slate-400 mb-2">{item._id}</div>
                    <img src={status(item.paymentStatus)} />
                    <div className="text-[#728F9E] mt-2">
                      Tanggal invoice :{" "}
                      <span className="font-semibold">
                        {formatDate(item.invoiceDate)}
                      </span>
                    </div>
                    <div className="text-[#728F9E]">
                      Tanggal Jatuh Tempo :{" "}
                      <span className="font-semibold">
                        {formatDate(item.expiredDate)}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="grid grid-cols-2 md:text-base text-sm"
                      hidden={item.paymentStatus == "PARTIAL"}
                    >
                      <span className=" text-[#26A69A]">Total Tagihan</span>
                      <span
                        className={`${
                          item.paymentStatus == "PAID"
                            ? "text-[#26A69A]"
                            : "text-red-500"
                        } text-right font-semibold`}
                      >
                        {formatCurrency(
                          item.paymentStatus == "PAID" ? item.paid : item.final
                        )}
                      </span>
                    </div>
                    <div hidden={item.paymentStatus !== "PARTIAL"}>
                      <div className="grid grid-cols-2">
                        <span className=" text-slate-400">Tagihan</span>
                        <span className="text-right text-emerald-400 font-semibold">
                          {formatCurrency(item.amount)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className=" text-slate-400">Sudah Dibayar</span>
                        <span className="text-right font-semibold">
                          {formatCurrency(item.paid)}
                        </span>
                      </div>
                      <div className="grid grid-cols-2">
                        <span className=" text-slate-400">Sisa Tagihan</span>
                        <span className="text-right text-red-500 font-semibold">
                          {formatCurrency(item.final)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div hidden={showInput(item.paymentStatus)} className="z-30">
                  <InputComponent
                    value={price}
                    setValue={setPrice}
                    submit={paymentInvoiceLink}
                    final={finalPrice}
                    allowed={allowedPartial}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TooltipModal show={tooltip} setShow={setTooltip} />
      <Footer companyLogo={companyLogo} />
    </div>
  );
};

export default Invoice;
