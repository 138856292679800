import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { onlyNumber } from "../config/global";
import { Transition } from "@headlessui/react";

interface InputProps {
  show: any;
  setShow: any;
}
const TooltipModal: FC<InputProps> = (props): JSX.Element => {
  return (
    <div
      className={`${props.show ? "relative z-50" : "hidden"}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={() => props.setShow(!props.show)}
    >
      <div className="fixed inset-0 backdrop-brightness-50 bg-opacity-75 transition-opacity">
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              className={`${
                props.show ? "slide-from-bottom" : "animate-top"
              } fixed-bottom md:hidden bg-white pb-14 pt-4 px-4 rounded-lg space-y-4 border border-slate-400 mt-20 backdrop-brightness-50 bg-opacity-75 transition-opacity`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="text-center text-lg">Auto-Pay</div>

              <p className="text-justify text-[#728F9E] text-sm">
                Invoice ini adalah invoice auto-pay, dimana pembayaran invoice
                ini akan otomatis dilakukan oleh sistem dengan memotong saldo
                utama usersetelah user melakukan top up
              </p>
            </div>

            <div className="w-[30%] slide-from-top hidden md:block bg-white p-4 rounded-lg space-y-2 border border-slate-400">
              <div className="text-center text-xl">Auto-Pay</div>
              <p className="text-justify text-[#728F9E]">
                Invoice ini adalah invoice auto-pay, dimana pembayaran invoice
                ini akan otomatis dilakukan oleh sistem dengan memotong saldo
                utama usersetelah user melakukan top up
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TooltipModal;
