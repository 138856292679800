import Index from "./pages/index";

const Routes: any = [
  ,
  {
    path: `/:id`,
    component: Index,
  },
 
];

export default Routes;
