export const ValidateError = (respon) => {
  switch (parseInt(respon.detail.status_code)) {
    case 400:
      if (respon.detail.type == "INVALID_COMPANY") {
        return "Company Tidak Sah";
      } else if (respon.detail.type == "USER_NOT_FOUND") {
        return "Pengguna Tidak Ditemukan";
      } else if (respon.detail.type == "USER_NOT_FOUND") {
        return "Pengguna Tidak Ditemukan";
      } else if (respon.detail.type == "FAILED_TO_REQUEST") {
        return "Terjadi Kesalahan Server";
      } else if (
        respon.detail.type ==
        "INPUT_AMOUNT_MUST_BE_SMALLER_OR_EQUAL_TO_INVOICE_FINAL"
      ) {
        return "Nominal harus lebih kecil dari total tagihan";
      } else if (respon.detail.type == "FAILED_CREATE_VA") {
        return "Gagal membuat QR";
      } else if (respon.detail.type == "USER_INACTIVE") {
        return "Pengguna Tidak Ditemukan";
      } else if (respon.detail.type == "PROVIDER_TROUBLE") {
        return "Provider sedang bermasalah";
      } else if (respon.detail.type == "WRONG_PASSWORD_COUNT_EXCEEDED") {
        return "Percobaan Melebihi Batas, Silahkan Reset Password Anda";
      } else if (respon.detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (respon.detail.type == "REQUEST_AMOUNT_NOT_MATCH_WITH_CONFIG") {
        return "Harga tidak sesuai dengan maksimal dan minimal harga di metode pembayaran";
      } else if (respon.detail.type == "OTP_NOT_FOUND") {
        return "OTP Tidak Ditemukan";
      } else if (respon.detail.type == "FAILED_VALIDATE_OTP_TRANSACTION") {
        return "Kode OTP salah";
      } else if (respon.detail.type == "BAD_CREDENTIAL") {
        return "Password yang anda masukan salah";
      } else if (respon.detail.type == "SECURITY_CODE_NOT_MATCH") {
        return "Pin salah";
      } else if (respon.detail.type == "USER_DO_NOT_HAVE_SECURITY_CODE") {
        return "Pengguna Tidak Memiliki Kode Keamanan";
      } else if (respon.detail.type == "SECURITY_CODE_TOKEN_NOT_FOUND") {
        return "Token Kode Keamanan Tidak Ditemukan";
      } else if (respon.detail.type == "FAILED_TO_REQUEST_CREATE_QRIS_BANK") {
        return "Gagal membuat QR";
      } else if (respon.detail.type == "LINK_AJA_NOT_CONNECTED") {
        return "";
      } else if (respon.detail.type == "BANK_DKI_ACCOUNT_NOT_CONNECTED") {
        return "";
      } else if (respon.detail.type == "FAILED_TO_CREATE_VA_BANK") {
        return "Gagal membuat VA Bank";
      } else if (respon.detail.type == "BAD_REQUEST") {
        return "";
      } else if (respon == "FAILED_TO_REQUEST") {
        return "Metode Pembayaran Belum Tersedia";
      } else if (respon.detail.type == "INVALID_DATE_FORMAT") {
        return "Format Tanggal Tidak Valid";
      } else if (respon.detail.type == "INVALID_PHONE_NUMBER") {
        return "Nomor Telepon Tidak Valid";
      } else if (respon.detail.type == "INVALID_EMAIL") {
        return "Email Tidak Valid";
      } else if (respon.detail.type == "INVALID_OBJECT_ID") {
        return "ID Obyek Tidak Valid";
      } else if (respon.detail.type == "INVALID_PASSWORD_LENGTH") {
        return "Panjang Password Tidak Valid";
      } else if (respon.detail.type == "FAILED_TO_REQUEST") {
        return "Gagal Meminta";
      } else if (respon.detail.type == "COMPANY_INITIAL_OR_NAME_EXIST") {
        return "Inisial atau Nama Perusahaan Ada";
      } else if (respon.detail.type == "DUPLICATE_EMAIL") {
        return "Email Duplikat";
      } else if (respon.detail.type == "TOKEN_EXPIRED") {
        return "Token Kadaluwarsa";
      } else if (respon.detail.type == "TOKEN_ALREADY_USED") {
        return "Token Sudah Digunakan";
      } else if (respon.detail.type == "WRONG_CONFIRMATION_PASSWORD") {
        return "Password Konfirmasi Salah";
      } else if (respon.detail.type == "WRONG_PASSWORD") {
        return "Username atau Password Salah";
      } else if (respon.detail.type == "ACCOUNT_NOT_FOUND") {
        return "Akun belum terhubung";
      } else if (respon.detail.type == "FAILED_CREATE_OTP_TRANSACTION") {
        return "Password yang anda masukkan salah";
      } else if (respon.detail.type == "ACCOUNT_REQUESTED") {
        return "Akun belum terhubung";
      } else if (
        respon.detail.type ==
        "CHECKOUT_USER_ID_MUST_BE_NULL_IF_ACCESSED_BY_GUEST"
      ) {
        return "";
      } else if (respon.detail.type == "AMOUNT_MUST_BE_BIGGER_THAN_ZERO") {
        return "Nominal Harus Lebih Dari 0";
      } else {
        return respon.detail.type;
      }
    case 401:
      if (respon.detail.type == "WRONG_PASSWORD") {
        return "Username atau Password Salah";
      } else if (respon.detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (respon.detail.type == "UNATHORIZED") {
        return "";
      } else if (respon.detail.type == "UNAUTHORIZED") {
        return "Tidak Diizinkan";
      } else {
        return respon.detail.type;
      }
    case 403:
      if (respon.detail.type == "UNAUTHORIZED_ROLE") {
        return "Akses Tidak Diizinkan";
      } else if (respon.detail.type == "ACCESS_FORBIDDEN") {
        return "Dilarang Akses";
      } else {
        return respon.detail.type || respon.detail.message;
      }
    case 404:
      if (respon.detail.type == "NOT_FOUND") {
        return "Tidak Ditemukan";
      } else if (respon.detail.type == "INVOICE_LINK_NOT_FOUND") {
        return "";
      } else if (respon.detail.type == "COMPANY_NOT_FOUND") {
        return "Instansi/Perusahaan tidak ditemukan";
      } else if (respon.detail.type == "CHECKOUT_NOT_FOUND") {
        return "Checkout tidak ditemukan";
      } else {
        return respon.detail.type;
      }
    case 422:
      if (respon.data.detail.type == "value_error.missing") {
        return "Pilih Metode Pembayaran";
      } else if (respon.detail.type == "ACCESS_FORBIDDEN") {
        return "Dilarang Akses";
      } else {
        return respon.detail.type || respon.detail.message;
      }
    case 500:
      if (respon.detail.type == "INTERNAL_SERVER_ERROR") {
        return "Kesalahan Server";
      } else {
        return respon.detail.type;
      }
    default:
      return respon.detail.type;
  }
};
// export const ValidateError = (respon, nominal = 0) => {
//   // console.log(respon, "tes");
//   if (respon == "Member tidak ditemukan") {
//     return "Member tidak ditemukan";
//   } else if (respon == "Bank tidak ditemukan") {
//     return "Bank tidak ditemukan";
//   } else if (respon == "FAILED_TO_REQUEST") {
//     return "Metode Pembayaran Belum Tersedia";
//   } else if (respon == "Member bank tidak ditemukan") {
//     return "Member bank tidak ditemukan";
//   } else if (respon == "Saldo tidak mencukupi") {
//     return "Saldo tidak mencukupi";
//   } else if (respon == "value_error.missing") {
//     return "Pilih Metode Pembayaran";
//   } else if (
//     respon ==
//     `Total potongan adalah Rp.${nominal}. Nominal harus lebih dari total potongan`
//   ) {
//     return `Total potongan adalah Rp.${nominal}. Nominal harus lebih dari total potongan`;
//   } else if (respon == "Transaksi tidak ditemukan") {
//     return "Transaksi tidak ditemukan";
//   } else if (respon == "DONATION_NOT_FOUND") {
//     return "Donasi Tidak Ditemukan";
//   } else if (respon == "REAUTHENTICATION_TOKEN_REQUIRED") {
//     return "Token Diperlukan";
//   } else if (respon == "INVALID_TOKEN") {
//     return "Token Salah";
//   } else if (respon == "EXPIRED_TOKEN") {
//     return "Token Expired";
//   } else if (respon == "WRONG_TRANSACTION") {
//     return "Transaksi Salah";
//   } else if (respon == "WRONG_USER_ID") {
//     return "User Id Salah";
//   } else if (respon == "User tidak ditemukan") {
//     return "User tidak ditemukan";
//   } else if (respon == "Company tidak ditemukan") {
//     return "Company tidak ditemukan";
//   } else if (respon == "User tidak memiliki email") {
//     return "User tidak memiliki email";
//   } else if (respon == "Device baru, mohon masukkan kode otp") {
//     return "Device baru, mohon masukkan kode otp";
//   } else if (respon == "Kode OTP tidak ditemukan") {
//     return "Kode OTP tidak ditemukan";
//   } else if (respon == "Kode OTP suspended") {
//     return "Kode OTP suspended";
//   } else if (respon == "Kode OTP expired") {
//     return "Kode OTP expired";
//   } else if (respon == "OTP salah") {
//     return "OTP salah";
//   } else if (respon == "Your Account is Disabled") {
//     return "Akun Anda di Nonaktifkan";
//   } else if (respon == "Incorrect old password") {
//     return "Password lama tidak sesuai";
//   } else if (respon == "User not found") {
//     return "User tidak di temukan";
//   } else if (respon == "Email tidak valid") {
//     return "Email tidak valid";
//   } else if (respon == "Email sudah terdaftar") {
//     return "Email sudah terdaftar";
//   } else if (respon == "Gagal update data") {
//     return "Gagal update data";
//   } else if (
//     respon == "Belum bisa membuat virtual account dengan bank terkait"
//   ) {
//     return "Belum bisa membuat virtual account dengan bank terkait";
//   } else if (respon == "Gagal mengirim request create va ke gateway") {
//     return "Gagal mengirim request create va ke gateway";
//   } else if (respon == "COMPANY_ACCOUNT_NOT_FOUND") {
//     return "Company Akun Tidak ditemukan";
//   } else if (respon == "WRONG_ACCOUNT_NAME") {
//     return "Nama Akun Salah";
//   } else if (respon == "BANK_ACCOUNT_NOT_FOUND") {
//     return "Akun Bank Tidak Di temukan";
//   } else if (respon == "DONATION_HAS_ENDED") {
//     return "Donasi telah Berakhir";
//   } else if (respon == "MIN_DONATION_10000") {
//     return "Minimal Donasi Rp 10.000";
//   } else if (respon == "DONATION_PROGRESS_NOT_FOUND") {
//     return "Donasi tidak di temukan";
//   } else if (respon == "QR_CODE_NOT_FOUND") {
//     return "Qr Code Tidak di temukan";
//   } else if (respon == "QR_CODE_EXPIRED") {
//     return "QR Code Expired";
//   } else if (respon == "User bukan member") {
//     return "User bukan member";
//   } else if (respon == "USER_ALREADY_HAVE_SECURITY_CODE") {
//     return "User Sudah memiliki kode Keamanan";
//   } else if (respon == "SECURITY_CODE_INVALID") {
//     return "Kode Keamanan Invalid";
//   } else if (respon == "WRONG_SECURITY_CODE") {
//     return "Kode Keamanan Salah";
//   } else if (respon == "SUSPENDED_SECURITY_CODE") {
//     return "Kode Keamanan di Suspend";
//   } else if (respon == "USER_HAVE_NO_SECURITY_CODE") {
//     return "User Tidak Memiliki Kode Keamanan";
//   } else if (respon == "REQUEST_FOR_RESET_OTP_TOO_QUICK") {
//     return "Permintaan request OTP untuk reset kode keamanan terlalu cepat. Mohon tunggu beberapa saat";
//   } else if (respon == "OTP_REQUEST_EXCEED_MAXIMUM_NUMBER_IN_ONE_DAY") {
//     return "PERMINTAAN OTP MELEBIHI JUMLAH MAKSIMUM DALAM SATU HARI";
//   } else if (respon == "OTP_CODE_EXPIRED") {
//     return "Kode OTP Expired";
//   } else if (respon == "WRONG_OTP_CODE") {
//     return "Kode OTP Tidak Sesuai";
//   } else if (respon == "EXTENSION_NOT_ALLOWED") {
//     return "Extensi Salah";
//   } else if (respon == "FAILED_UPLOADING_FILE") {
//     return "Gagal Upload file";
//   } else if (respon == "MERCHANT_NOT_EXIST") {
//     return "Merchant tidak di temukan";
//   } else if (respon == "MEMBER_NOT_EXIST") {
//     return "Member tidak di temukan";
//   } else if (respon == "MERCHANT_SHIP_NOT_EXIST") {
//     return "Kurir Merchant tidak di temukan";
//   } else if (respon == "AUTHENTICATION_FAILED") {
//     return "Authentikasi gagal";
//   } else if (respon == "BALANCE_NOT_ENOUGH") {
//     return "Saldo saat create order tidak cukup";
//   } else if (respon == "FAILED_CREATING_ORDER") {
//     return "Order gagal di buat";
//   } else if (respon == "STATUS_NOT_VALID") {
//     return "Status order tidak valid";
//   } else if (respon == "CATEGORY_NOT_EXIST") {
//     return "Kategori Tidak di temukan";
//   } else if (respon == "PRODUCT_NOT_EXIST") {
//     return "Product Tidak di temukan";
//   } else if (respon == "MEMBER_ADDRESS_NOT_EXIST") {
//     return "Alamat Member tidak di temukan";
//   } else if (respon == "CART_NOT_EXIST") {
//     return "Keranjang Tidak di temukan";
//   } else if (respon == "ORDER_NOT_EXIST") {
//     return "Order Tidak di temukan";
//   } else if (respon == "INVALID_PRICE") {
//     return "Harga Salah";
//   } else if (respon == "INVALID_ORDER_QUANTITY") {
//     return "Harga Salah";
//   } else if (respon == "Harga Order salah") {
//     return "Harga Salah";
//   } else if (respon == "FAILED_REVERSE_BALANCE") {
//     return "Gagal Mengembalikan Saldo";
//   } else if (respon == "PRODUCT_QUANTITY_NOT_EXIST") {
//     return "Jumlah Product Tidak Tersedia";
//   } else if (respon == "FAILED_SEND_NOTIFICATION") {
//     return "Gagal Mengirim Notifikasi";
//   } else if (respon == "UNAUTHORIZED_ROLE") {
//     return "Role tidak bisa di akses";
//   } else if (respon == "CHARGE_NOT_EXIST") {
//     return "Data charge tidak di temukan";
//   } else if (respon == "FAILED_UPDATE_CHARGE_STATUS") {
//     return "Gagal mengubah mutasi charge";
//   } else if (respon == "PULSE_PRODUCT_NOT_EXIST") {
//     return "Pulsa tidak tersedia";
//   } else if (respon == "FAILED_PULSE_INQUIRY") {
//     return "Gagal Mengambil data Pulsa";
//   } else if (respon == "PLN_CUSTOMER_NUMBER_NOT_FOUND") {
//     return "PLN Customer Number tidak di temukan";
//   } else if (respon == "PLN_PRODUCT_NOT_EXIST") {
//     return "PLN Tidak tersedia";
//   } else if (respon == "TRANSACTION_NOT_EXIST") {
//     return "Tidak ada Teransaksi";
//   } else if (respon == "FAILED_PLN_INQUIRY") {
//     return "PLN Inquiry gagal";
//   } else if (respon == "FAILED_BPJS_INQUIRY") {
//     return "BPJS Inquiry gagal";
//   } else if (respon == "FAILED_PBB_INQUIRY") {
//     return "PBB Inquiry gagal";
//   } else if (respon == "PBB_CODE_NOT_EXIST") {
//     return "Kode PBB Tidak di temukan";
//   } else if (respon == "FAILED_PDAM_INQUIRY") {
//     return "PDAM Inquiry gagal";
//   } else if (respon == "PDAM_CODE_NOT_EXIST") {
//     return "Kode PDAM Tidak di Temukan";
//   } else if (respon == "FAILED_INTERNET_TV_INQUIRY") {
//     return "Internet TV Inquiry gagal";
//   } else if (respon == "INTERNET_TV_PRODUCT_NOT_EXIST") {
//     return "Product Internet Tv Tidak di temukan";
//   } else if (respon == "ACCOUNT_NOT_FOUND") {
//     return "Akun Tidak Di Temukan";
//   } else if (respon == "STOCK_PRODUCT_NOT_EXIST") {
//     return "Stok Produk Tidak Mencukupi";
//   } else {
//     return respon;
//   }
// };
