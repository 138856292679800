import React, { FC,Component, useEffect, useState } from "react";
import {} from "../core/feature/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import NotFoundImg from "../assets/images/icon_checkout/NotFound.svg";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

interface NotFoundProps {
  show:any,
}
const NotFound: FC<NotFoundProps> = (props): JSX.Element => {
  const title = "Tagihan";
  document.title = title;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);
  return (
    <>
      <div className="w-full md:w-3/4 2xl:2/5 h-full font-poppins mx-auto" hidden={props.show}>
        <div className="space-y-8">
          <div className="mt-24">
            <img src={NotFoundImg} alt="" className="mx-auto w-[100%] md:w-[50%] lg:w-[50%] xl:w-[40%] 2xl:w-[40%]" />
          </div>
          <div className="md:text-xl 2xl:text-2xl text-center space-y-4">
            <div className="text-[#EC1A1A] mx-auto">Halaman Tidak di Temukan</div>
            <div className="text-sm md:text-lg 2xl:text-xl">
              Masukan Link Invoice dengan Benar. <br /> Silahkan Chekout Ulang
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default NotFound;
