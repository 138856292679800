import Config from "../../config/Config";
import {
  createArrayEntityFromObject,
  createQueryUrl,
  getEntity,
} from "../../config/global";
const axios = require("axios");

const baseUrlDev = Config.BaseUrl;

export const getInvoice = (id) => {
  let url = `${baseUrlDev}invoice/guest/v3/invoice_link/${id}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const invoicePayment = (id, data,type,userType) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${baseUrlDev}invoice/${userType}/invoice/created_by_${type}/${id}/create_checkout`,
        data,
        {}
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
