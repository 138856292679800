import React, { FC } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import Logo from "../assets/images/icon_invoicelink/logo-text.svg";
interface FooterProps {
  companyLogo: any;
}
const Footer: FC<FooterProps> = (props): JSX.Element => {
  const company: any = useAppSelector((state) => state.company.company);

  return (
    <div>
      <div className="mt-auto px-4 py-4 flex flex-col justify-center items-center space-x-4">
        <div className="text-emerald-400">Powered by</div>
        <img src={Logo} className="w-44" />
      </div>
    </div>
  );
};

export default Footer;
